import React from "react"
import {graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Product from "../components/Product"

const Title = styled.h3`
  font-weight: bold;
  font-size: 32px;
  margin: 0 0 40px;
`

const ProductsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 31px;
  @media (max-width: 768px) {
    column-gap: 15px;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 414px) {
    column-gap: 0;
    grid-template-columns: 1fr;
  }
`

const OtherProducts = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulProduct {
        nodes {
          title
          slug
          images {
            title
            fluid(maxWidth: 300) {
                ...GatsbyContentfulFluid_withWebp
            }
          }
          price
        }
      }
    }
  `)

  const products = data.allContentfulProduct.nodes

  return (
    <div style={{marginBottom: '-40px'}}>
      <Title>Other products</Title>
      <ProductsWrapper>
        {products.map((node, i) => {
            if (i < 3) {
                return <Product data={node} key={i} />
            } else {
                return ""
            }
        })}
      </ProductsWrapper>
    </div>
  )
}

export default OtherProducts
