import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image"
import styled from "styled-components"

import Layout from "../components/layout"
import arrow from "../images/arrow.svg"
import Seo from "../components/seo"
import Triangle from "../components/styles/Triangle"
import OtherProducts from "../components/OtherProducts"
import ButtonShop from "../components/ButtonShop"
import CartHandler from "../components/CartHandler"

const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #0a0908;
  margin-bottom: 42px;
  a {
    color: #d8d8d8;
  }
`

const Arrow = styled.span`
  background: url(${arrow}) no-repeat;
  background-size: cover;
  width: 18px;
  height: 15px;
  display: block;
  margin: 0 10px;
`

const Product = styled.div`
  display: flex;
  margin-bottom: 120px;
  @media (max-width: 768px) {
    padding: 0 6px;
    margin-bottom: 0;
    flex-direction: column;
  }
`

const ProductThumbs = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;
  flex-shrink: 0;
  margin-right: 30px;
  @media (max-width: 768px) {
    order: 1;
    margin: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
  }
`
const ProductThumb = styled.button`
  display: block;
  margin: 0 0 30px;
  width: 100%;
  height: 140px;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    margin: 0;
    width: 100%;
    height: auto;
  }
`

const Thumb = styled(Img)`
  img {
    object-position: top !important;
  }
`

const ProductImageWrapper = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
`

const ProductImg = styled(Img)`
  display: block;
  margin: 0;

  width: 100%;
  height: 600px;

  @media (max-width: 768px) {
    order: 0;
    margin-bottom: 16px;
  }
`

const ProductDetails = styled.div`
  width: 300px;
  flex-shrink: 0;
  font-size: 18px;
  line-height: 28px;
  color: #000;
  margin: 0 0 0 28px;
  p {
    margin: 0 0 30px;
  }
  @media (max-width: 768px) {
    order: 2;
    margin: 0 0 40px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
  }
`

const ProductDetailsHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 18px;
  margin-bottom: 24px;
  h2 {
    margin: 0;
    margin-bottom: 15px;
  }
  @media (max-width: 768px) {
    h2 {
      font-size: 24px;
    }
  }
`
const SizeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  margin-bottom: 16px;
  p {
    margin: 0 10px 0 0;
  }
`
const SizeOpener = styled.div`
  display: block;
  border: 1px solid #0a0908;
  background: none;
  cursor: pointer;
  font-size: 20px;
  line-height: 24px;
  color: #0a0908;
  padding: 8px 41px 8px 13px;
  position: relative;
  /* width: 100px; */
  &:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 12px;
    background: url(${arrow}) no-repeat 0 50%;
    background-size: cover;
    width: 20px;
    height: 14px;
    transform: rotate(90deg);
  }
  &.opened {
    &:after {
      transform: rotate(-90deg);
    }
  }
  &:hover {
    color: #0a0908;
    background: #fff;
  }
`

const SizesDrop = styled.div`
  border: 1px solid #0a0908;
  border-top: 0;
  position: absolute;
  top: 41px;
  left: -1px;
  width: calc(100% + 2px);
  background: #bbaad8;
  button {
    display: block;
    padding: 8px 5px 4px 13px;
    border: 0;
    background: none;
    cursor: pointer;
    text-align: left;
    width: 100%;
    transition: all 0.15s ease-out;
    &:hover {
      color: #0a0908;
      background: #fff;
    }
  }
`

const Buttons = styled.div`
  /* display: flex; */
  /* border: 1px solid #0a0908; */
  /* font-size: 18px; */
`

const QtyButton = styled.button`
  display: block;
  padding: 0;
  border: 0;
  cursor: pointer;
  font-size: 20px;
  background: none;
  line-height: 40px;
  color: #0a0908;
  width: 36px;
  text-align: center;
  flex-shrink: 0;
  &:hover {
    background: #fff;
  }
`

const AddButton = styled(ButtonShop)`
  display: block;
  padding: 0;
  border: 0;
  cursor: pointer;
  font-size: 20px;
  background: none;
  line-height: 40px;
  color: #0a0908;
  text-align: center;
  width: 100%;
`

function ProductTemplate({
  data: {
    contentfulProduct: { title, description, sizes, images, price, guid },
  },
}) {
  const [imgSrc, setImgSrc] = useState(images[0].fluid)
  const [size, setSize] = useState(sizes ? sizes[0] : guid)
  const [openSizes, setSizesOpener] = useState(false)
  //   const [qty, setQty] = useState(1);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", "ViewContent", {
          content_name: title,
          content_category: "Merchandise",
        })
      }
    }
  }, [])

  return (
    <Layout>
      <Seo title={`Merchandise | ${title}`} />
      <div style={{ maxWidth: "960px", margin: "0 auto" }}>
        <Breadcrumbs>
          <Link title="Merchandise" to="/merchandise">
            Merch
          </Link>
          <Arrow />
          {title}
        </Breadcrumbs>
        <Product>
          <ProductThumbs>
            {images.map((image, i) => (
              <ProductThumb
                key={i}
                onClick={() => {
                  setImgSrc(image.fluid)
                }}
                onKeyDown={() => {
                  setImgSrc(image.fluid)
                }}
              >
                <Thumb fluid={image.fluid} alt={image.title} />
              </ProductThumb>
            ))}
          </ProductThumbs>
          <ProductImageWrapper>
            <ProductImg fluid={imgSrc} alt={title} objectPosition="100% 0%" />
          </ProductImageWrapper>
          <ProductDetails>
            <ProductDetailsHeader>
              <h2>{title}</h2>
              <span>€{price.toFixed(2)}</span>
            </ProductDetailsHeader>
            {documentToReactComponents(JSON.parse(description.raw))}
            {sizes && sizes.length && (
              <SizeWrapper>
                <p>Choose your size</p>
                <SizeOpener
                  className={openSizes ? "opened" : ""}
                  onClick={() => {
                    openSizes ? setSizesOpener(false) : setSizesOpener(true)
                  }}
                  onKeyDown={() => {
                    openSizes ? setSizesOpener(false) : setSizesOpener(true)
                  }}
                >
                  {size.label}
                  {openSizes && (
                    <SizesDrop>
                      {sizes.map((size, j) => (
                        <button
                          key={j}
                          onClick={() => {
                            setSize(size)
                          }}
                          onKeyDown={() => {
                            setSize(size)
                          }}
                        >
                          {size.label}
                        </button>
                      ))}
                    </SizesDrop>
                  )}
                </SizeOpener>
              </SizeWrapper>
            )}
            <Buttons>
              <p>Currently not available</p>
              {/* <AddButton
                action="add"
                type="ticket"
                guid={size.guid || guid}
                price={price}
                name={title}
                category={size.label}
              />
              <CartHandler
                ticket={{
                  guid: size.guid || guid,
                  price: price,
                  name: title,
                  category: size.label,
                }}
                ev={{
                  title: size.label,
                }}
              /> */}
            </Buttons>
          </ProductDetails>
        </Product>
        <OtherProducts />
      </div>
      <Triangle color="#bbaad8"></Triangle>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    contentfulProduct(id: { eq: $id }) {
      title
      slug
      price
      guid
      description {
        raw
      }
      sizes {
        guid
        label
      }
      images {
        title
        fluid(maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

export default ProductTemplate
